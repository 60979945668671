import { createTheme } from '@mui/material/styles';
import { red } from '@mui/material/colors';

// const roboto = Roboto({
//   weight: ['300', '400', '500', '700'],
//   subsets: ['latin'],
//   display: 'swap',
// });

// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: '#009963',
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    white: {
      main: '#efefef',
    },
  },
  typography: {
    // fontFamily: roboto.style.fontFamily,
    fontSize : 15,
    h1: {
      
      fontSize: "1.2rem"
    },
   
    h3: {
      // paddingTop : "400px",
      fontSize: "1.5rem"
      ,fontWeight : 500,
      paddingTop : "15px",
      paddingBottom : "15px",
    }, 
    h2: {
      lineHeight : "35px",
      letterSpacing : "0.9px",
      
      paddingRight : "16px",
      paddingTop : "20px",
      paddingBottom : "20px",
      fontSize: "2.13rem",
    }, 
    h4: {
      lineHeight : "24px",
      fontSize: "1.4rem"
      
    }, 
    h5: {
      lineHeight : "21px",
      color : "#E8DECF", 
      fontSize: "1.2 rem"
    },

  },
});

export default theme;
