import { Provider } from "react-redux";
import { store } from "./store";
import { persistStore } from "redux-persist";

// Just add the following 2 lines
persistStore(store);
export default function ReduxProvider({
  children,
}) {
  return <Provider store={store}>{children}</Provider>;
}