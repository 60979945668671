import React, { useState } from 'react';

import { Button, Checkbox, FormControlLabel, TextField, Typography } from "@mui/material"
import Box from "./Box"
import _ from "lodash";
import { useEffect } from 'react';
import { setMenu } from '../redux/slice';
import { useDispatch } from 'react-redux';
const Questions = (
    { answers, onComplete, myQuestions, }
) => {




    const dispatch = useDispatch()
    useEffect(() => {

    }, [answers])




    var showQuestions = myQuestions && myQuestions.length > 0;
    myQuestions = _.orderBy(myQuestions, q =>  q.type)
    return (
        <Box sx={{
            pb: 6
        }}>

            <Typography variant='h3'>
                My Questions
            </Typography>


            {showQuestions && myQuestions.map(mq => {
                var q = _.find(questions, q => mq.id == q.id)

                const a = _.find(answers, ans => ans.questionID == q.id)
                if (q.type == "checkbox") {

                    return buildCheckbox(q, a, onComplete)
                }
                else {

                    return buildTextfield(q, a, onComplete)
                }

            })}
            {!showQuestions &&

                <Box>
                    <Typography variant='h3'>
                        Need more information about properties?

                    </Typography>
                    <Button
                        variant='text'
                        onClick={() => dispatch(setMenu("questionBank"))}
                    >

                        Add a question
                    </Button>
                </Box>
            }
        </Box>
    )
}


const buildCheckbox = (q, a, onComplete) => {
    var currentAnswer = false

    if (a) {
        if (typeof a.value == "boolean") {
            currentAnswer = a.value
        }
        else {
            if (a.value == "true") {
                currentAnswer = true
            }

        }

    }


    return (<Box key={q.id}
    
        sx = {{m : 1}}
    >

        <FormControlLabel
  

            checked={currentAnswer} control={<Checkbox
                checked={currentAnswer}

                onChange={({ target }) => {

              
                    onComplete(q.id, target.checked)
                }}
            />
            }
            label={q.text}
            labelPlacement="end"
        />
    </Box>)
}
const buildTextfield = (q, a, onComplete) => {

    const value = a ? a.value : ""


    return (<Box key={q.id}>

        <TextField
        sx = {{m:1, }}
            onChange={({ target }) => {

                onComplete(q.id, target.value)
            }}
            label={q.text}
            id={q.id.toString()}

            value={value}
            size="small"
        />
    </Box>)
}
const questions = [{
    intention: "rent",
    id: 1,
    text: "Are bills included",
    type: "checkbox"
},
{
    intention: "rent",
    id: 2,
    text: "Can I decorate",
    type: "checkbox"
},
{
    intention: "rent",
    id: 3,
    text: "Is it pet friendly",
    type: "checkbox"
},
{

    id: 4,
    text: "What is the internet speed",
    type: "textfield"
},
{
    intention: "buy",
    id: 5,
    text: "Is the seller negiotable?",
    type: "textfield"
},
{
    intention: "buy",
    id: 6,
    text: "Why is the owner selling?",
    type: "textfield"
},
{
    intention: "buy",
    id: 7,
    text: "Is there an onward chain",
    type: "checkbox"
},
{
    intention: "buy",
    id: 8,
    text: "How old is the property?",
    type: "textfield"
},
{
    id: 9,
    text: "Have the sellers had any offers",
    type: "checkbox"
},
{
    intention: "buy",
    id: 5,
    text: "Is the seller negiotable",
    type: "textfield"
},
{
    intention: "buy",
    id: 5,
    text: "Is the seller negiotable",
    type: "textfield"
},


]

export default Questions