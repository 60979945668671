import { initializeApp } from 'firebase/app';

import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBMrWTveiuqelNqkh2rWzarpUz1nWzJYug",
  authDomain: "home-bliss-ext.firebaseapp.com",
  projectId: "home-bliss-ext",
  storageBucket: "home-bliss-ext.appspot.com",
  messagingSenderId: "227727408271",
  appId: "1:227727408271:web:75a16212ba3c500849c4fa",
  measurementId: "G-BY7N70ZNK5"
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const firestore = getFirestore(app);
const auth = getAuth(app);
// const functions = getFunctions(app)

export { app, firestore, auth, }
