import React, { useState } from 'react';
import { Modal, Box, Grid, Typography, TextField, Button, InputAdornment, IconButton } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { signInWithEmailAndPassword, createUserWithEmailAndPassword } from "firebase/auth";
import { auth, firestore } from '../firebaseConfig'; // Make sure to configure your Firebase
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { setUser } from '../redux/slice';
import { useDispatch, useSelector } from 'react-redux';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const SignIn = ({ open, handleClose }) => {


  const dispatch = useDispatch();
  const authState = useSelector((state) => state);
  const [isSignIn, setIsSignIn] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [firstName, setFirstName] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');


  const reset = () => {

    if (email && email.length > 4) {
        sendPasswordResetEmail(auth, email).then(res => {


            setError("Check your inbox/spam folder for our email")
        }).catch(err => {
            setError("Unable to send email.")

        })
    }
    else {
        setError("Please add your email")
    }

}
const saveUser = (userID) => {

    const tempUser = {
        id: userID,
        email: email,
        name: firstName,
        dateCreated: moment().toDate(),
        platformCreation: "web"
    }

    setDoc(doc(firestore, "users", userID), tempUser).then(res => {


        dispatch(setUser(tempUser))
    })

}

const getUser = (userID) => {


  const ref = doc(firestore, "users", userID);

  getDoc(ref).then(res => {

      const user = { id: res.id, ...res.data() }
      handleClose()
      dispatch(setUser(user))
  })
}

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleEmailPasswordSignIn = async () => {
    if (isSignIn) {
      signInWithEmailAndPassword(auth, email, password).then(res => {
        getUser(res.user.uid);
      })
      .catch((error) => {
        const errorMessage = error.message;
        console.log(errorMessage);

        if (errorMessage === "auth/email-already-in-use") {
          setError("It looks like you already have an account");
        } else if (errorMessage === "auth/invalid-credential" || errorMessage === "auth/invalid-email" || errorMessage === "auth/wrong-password") {
          setError("Your email or password is invalid");
        } else {
          setError("Unable to sign in. Please check your credentials again");
        }
      });
    } else {
      try {
        createUserWithEmailAndPassword(auth, email, password)
          .then((userCredential) => {
            const user = userCredential.user;
            saveUser(user.uid);
          })
          .catch((error) => {
            const errorMessage = error.message;
            console.log(errorMessage);

            if (errorMessage === "auth/email-already-in-use") {
              setError("It looks like you already have an account");
            } else if (errorMessage === "auth/invalid-email" || errorMessage === "auth/wrong-password") {
              setError("Your email or password is invalid");
            } else {
              setError("Unable to sign in. Please check your credentials again");
            }
          });
      } catch (error) {
        setError(error.message);
      }
    }
  };

  return (
    <Modal
      open={false}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container spacing={2} justifyContent="center" direction="row" style={{ minHeight: '50vh', mx: 3 }}>
          <Grid item xs={8} md={10}>
            <Typography sx={{ mt: 3 }} variant="h4" gutterBottom>
              {isSignIn ? "Log in" : "Sign up"} to Homebound
            </Typography>
          </Grid>
          <Grid item xs={10} md={10}>
            {!isSignIn && (
              <TextField
                label="Name"
                variant="outlined"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                fullWidth
              />
            )}
          </Grid>
          <Grid item md={10}>
            <TextField
              type="email"
              label="Email"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
            />
          </Grid>
          <Grid item md={10}>
            <TextField
              type={showPassword ? 'text' : 'password'}
              label="Password"
              variant="outlined"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(ev) => {
                if (ev.key === 'Enter') {
                  handleEmailPasswordSignIn();
                }
              }}
            />
          </Grid>
          <Grid item md={10}>
            <Box sx={{ alignItems: "center", width: "100%" }}>
              <Button variant="contained" sx={{ width: "100px" }} color="primary" onClick={handleEmailPasswordSignIn}>
                {isSignIn ? "Login" : "Sign Up"}
              </Button>
            </Box>
          </Grid>
          <Grid item md={12} sx={{ justifyContent: "center", display: "flex" }}>
            <Box sx={{ alignItems: "center", width: "100%" }}>
              <Button variant="text" color="primary" sx={{ textTransform: "none" }} onClick={() => setIsSignIn(prev => !prev)}>
                {!isSignIn ? "Already have an account?" : "Create an account"}
              </Button>
            </Box>
          </Grid>
          {isSignIn && (
            <Grid item md={12}>
              <Box sx={{ alignItems: "center", width: "100%" }}>
                <Button variant="text" color="primary" sx={{ textTransform: "none" }} onClick={reset}>
                  Reset Password
                </Button>
              </Box>
            </Grid>
          )}
          {error && (
            <Grid item md={10}>
              <Typography variant="body1" color="error" align="center">
                {error}
              </Typography>
            </Grid>
          )}
        </Grid>
      </Box>
    </Modal>
  );
};

export default SignIn;
