import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  authState: false,
  snackMessage: "test"
  , guests: []
};

export const mainSlice = createSlice({
  name: "main",
  initialState,
  reducers: {
    setAuthState: (state, action) => {
      state.authState = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setUserID: (state, action) => {
      state.userID = action.payload;
    },
    setProperties: (state, action) => {
      state.properties = action.payload;
    },
    setProperty: (state, action) => {
      state.property = action.payload;
    },
    setHomes: (state, action) => {
      state.homes = action.payload;
    },
    setSnackMessage: (state, action) => {
      state.snackMessage = action.payload
      state.openSnack = true
    },
    closSnackbar: (state, action) => {

      state.openSnack = false
    },
    setMenu: (state, action) => {

      state.menu = action.payload
    },
    setSignOut: (state, action) => {
      state.user = null
      state.userID = null
      state.homes = []
      state.properties = []

    }


  },
});

export const { setHomes, setProperty, setMenu, setSnackMessage, closSnackbar, setUserID, setAuthState, setUser, setProperties, setSignOut } = mainSlice.actions;
export const mainReducer = mainSlice.reducer;