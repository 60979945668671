/* eslint-disable react/prop-types */
import { Grid, Typography, InputAdornment, IconButton, Input } from "@mui/material"
import Box from "./Box"
import React, { useState, useRef } from "react";
import { Send } from "@mui/icons-material";



export const UserNotes = ({
    userID,
    notes = [], addNote,
}) => {


    const messagesEndRef = useRef(null)

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
    }
    const [input, setInput] = useState('');

    const handleInputChange = (event) => {
        setInput(event.target.value);
    };

    const handleSendMessage = () => {

        var tempVal = input
        if (tempVal.trim() !== '') {
            scrollToBottom()
            addNote(tempVal)
            setInput('');
        }
    };

    return (
        <Box sx={{ width: "60%", mb: 1, }}>
            <Typography variant="h3">
                Notes
            </Typography>
            <Box sx={{ width: "100%", maxHeight: "600px", borderWidth: 1, borderStyle: "solid", }}>

                <Box elevation={1} style={{ height: "600px", maxHeight: "600px", overflowY: 'auto' }}>
                    <Box


                        container direction="column" justifyContent="flex-end" alignItems="stretch" spacing={1} sx={{ padding: '20px', overflowY: "scroll", height: "350px", }}>


                        {notes.map((message, index) => {


                         return (   <Box
                                sx={{ display: "flex",
                                 flexDirection: message.userID !== userID ? "row" : "row-reverse" }} 
                                 
                                 key={index} item>
                                    <Box>

                                <Box elevation={2} sx={{
                                    display: "flex",
                                    maxWidth: "200px",
                                    padding: '3px',

                                   marginBottom: message.userID == userID ? "2px" : "0px",
                                    borderRadius: "4px",
                                    // flexDirection: message.userID == userID ? "row-reverse" : "row",
                                    color: message.userID == userID ? '#fefefe' : '#121212',
                                    background: message.userID == userID ? '#2E9E6C' : '#f0f0f0',
                                    // alignSelf: message.userID == userID ? 'flex-end' : 'flex-start',
                                }}>
                                    <Typography sx={{ fontSize: "15px", alignSelf: message.userID == userID ? 'flex-end' : 'flex-start', }}>

                                        {message.text}
                                    </Typography>
                                </Box>
                                { message.userID !== userID &&<Typography sx={{ fontSize: "13px", alignSelf: message.userID == userID ? 'flex-end' : 'flex-start', marginBottom : "1px" }}>
                                    {message.name}
                                </Typography>}
                                </Box>

                            </Box>)
})}


                        <div

                            ref={messagesEndRef} />
                    </Box>
                </Box>

                <Box container spacing={2} alignItems="center">
                    <Box item xs={11}>

                        <Input
                            sx={{ borderWidth: "0px", ml: "2px", mr: "20px", width: "365px" }}
                            fullWidth
                            // variant="outlined"
                            placeholder="Add your personal notes..."
                            value={input}
                            variant="standard"
                            // onBlur={({target}) => addNote(target.value)}
                            onChange={handleInputChange}
                            onKeyDown={(ev) => {

                                if (ev.key === 'Enter') {
                                    // Do code here
                                    ev.preventDefault();
                                    handleSendMessage()
                                }
                            }}
                            // endAdornment={
                            //     <InputAdornment position="end">
                            //         <IconButton
                            //             // aria-label="toggle password visibility"
                            //             onClick={handleSendMessage}
                            //             // onMouseDown={handleMouseDownPassword}
                            //             edge="end"
                            //             disabled={input == ""}
                            //         >
                            //             <Send color={input == "" ? "error" : "primary"} />
                            //         </IconButton>
                            //     </InputAdornment>
                            // }
                        />
                    </Box>
                    {/* <Grid item xs={2}>
                        <Button variant="contained" color="primary" fullWidth onClick={handleSendMessage}>
                            Send
                        </Button>
                    </Grid> */}
                </Box>
                {/* {notes.map(m => {


                    return <Box
                        sx={{ right: "0px", padding: "1px", margin: "3px", width: "300px", marginBottom: 0 }}
                    >

                        <Typography
sx = {{ background: "aqua", borderRadius : "10px", px : "4px", marginBottom : "0px"}}
                        >
                            {m.text}
                        </Typography>
                        <Typography 
                        sx = {{marginTop : "0px", paddingTop : "0px", pl : 1,  lineHeight : "1"}}
                        variant="subtitle1">
                            {m.user}
                        </Typography>
                    </Box>
                })} */}
            </Box>
        </Box>
    )
}