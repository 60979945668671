import React, { useEffect } from 'react';
import { Typography, Grid, Paper, Button, Container, List, ListItem, ListItemText, IconButton } from '@mui/material';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useDispatch, useSelector } from 'react-redux';
import Header from '../components/HomeComponents/Header';
import Box from '../components/Box';
import { Account } from '../components/Account';
import { Layout } from '../components/Layout';

function HowTo(props) {
    document.title = "Our How-To Guide"

    const authState = useSelector((state) => state);
    const dispatch = useDispatch()
    const {

        user,
        properties
    } = authState






    return (
        <Layout>

            <Box sx={{
                display: "flex",
                m: 2,
                mb: 4
                // background : "green" 
            }}>

                <Container>
                    <Typography variant="h2" gutterBottom>
                        How to Use The homebound Extension
                    </Typography>
                    <Grid container spacing={4}>

                        {/* Step 1 */}
                        <Grid item xs={12} md={2}>

                            <Typography variant="h6">Step 1</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>

                            <Box

                            >
                                <Typography>
                                   Get the homebound extension

                                </Typography>

<Box
sx = {{   m:4}}
>
<Button
sx = {{width : 350
 
}}
variant='contained'
onClick={() => {window.open('https://chromewebstore.google.com/detail/homebound/fmadlhmgbckdgdjegnbdidmihmpbkbga', '_blank')}}
>
    Install Chrome Extension
</Button>
    <Typography>Opens Chrome extension webpage</Typography>
</Box>

                                <Box sx={{ m: 2 }}>

                                </Box>

                            </Box>

                        </Grid>
                        <Grid item xs={12} md={2}>

                            <Typography variant="h6">Step 2</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>

                            <Box

                            >
                                <Typography>
                                    Click on the homebound icon to start searching.

                                </Typography>
                                <Typography>
                                    If you don't see the hombound, you can find it by clicking on the extension (puzzle icon)
                                </Typography>
                                <Box sx={{ m: 2 }}>

                                    <img

                                        width={180}


                                        src='https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/homebound-extension.png?alt=media&token=4c2b4bf5-ec6a-4a23-bbcd-ef18952a788d' alt='homebound-ext' />
                                </Box>

                            </Box>

                        </Grid>

                        {/* Step 2 */}
                        <Grid item xs={12} md={2}>

                            <Typography variant="h6">Step 3</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>

                            <Box

                            >
                                <Typography>
                                    Look for the homebound tab on Zoopla and rightmove properties
                                </Typography>
                                <Box sx={{ m: 2 }}>
                                    <img src='https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/homebound-tab.png?alt=media&token=5d28d624-2a33-459e-adae-0fa93baed15e' alt='homebound-tab'
                                        width={490} />

                                </Box>

                            </Box>

                        </Grid>


                        <Grid item xs={12} md={2}>

                            <Typography variant="h6">Step 4</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>

                            <Box

                            >
                                <Typography>
                                    Rate, comment and review your proerties
                                </Typography>
                                <Box sx={{ m: 2 }}>

                                    <img

                                        width={480}


                                        src='https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/homebound-popup.png?alt=media&token=da7837ba-37eb-44d1-a86e-d0d49736e527' alt='homebound-pop-up' />
                                </Box>

                            </Box>

                        </Grid>


                        <Grid item xs={12} md={2}>

                            <Typography variant="h6">Step 5</Typography>
                        </Grid>
                        <Grid item xs={12} md={10}>

                            <Box

                            >
                                <Typography>
                                    Lookout for the ratings on rightmove and zoopla. Hover over the rating to read the latest comment/note
                                </Typography>
                                <Box sx={{ m: 2, justifyContent: "space-around" }} row >


                                    <Box>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/zoopla-status.png?alt=media&token=ccb8c184-0010-4edf-a50b-0517c59836bf' alt='zoopla-status' />
                                        <Typography variant='subtitle'>Zoopla</Typography>
                                    </Box>
                                    <Box>
                                        <img src='https://firebasestorage.googleapis.com/v0/b/home-bliss-ext.appspot.com/o/rightmove-status.png?alt=media&token=8af45987-1e2b-42a5-8584-d2355277410e' alt='rightmove-status' />
                                        <Typography variant='subtitle'>Rightmove</Typography>
                                    </Box>
                                </Box>

                            </Box>

                        </Grid>

                    </Grid>

                </Container>
                <Typography color={"primary"} sx={{ m: 12, alignSelf: "center", fontSize: 40 }} variant='h4'>
                    Happy Searching!
                </Typography>
            </Box>
        </Layout>
    );
}

export default HowTo;
