import React, { useEffect, useState } from 'react';
import { Box, Container } from "@mui/material"
import PropertyDetails from "../components/PropertyDetails"
import { useLocation, useParams } from 'react-router-dom';
import { Layout } from '../components/Layout';
import PropertyGrid from '../components/PropertyGrid';


export default function Details() {

  const location = useLocation();
  const wildcard = useParams()["*"];
  console.log("wildcard", wildcard, useParams())
  // const getProperties = (userID) => {


  //   const q = query(collection(db, "properties"), where("users", "array-contains", userID));

  //   const querySnapshot = getDocs(q);

  //   var properties = []
  //   querySnapshot.forEach((doc) => {


  //     const prop = { id: doc.id, ...doc.data() }

  //     properties.push(prop)



  //   }
  //   )

  //   setProperties(properties)
  // }
  useEffect(() => {
    const id = ""

  }, [location, wildcard])


  return (
    <Box>
      <Layout>
        {!wildcard && <PropertyGrid />}
        {wildcard && <PropertyDetails shareCode={wildcard} />}
      </Layout>
    </Box>

  )
}