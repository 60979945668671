import { Button, Chip, Grid, IconButton, InputAdornment, List, ListItem, ListItemButton, ListItemText, Modal, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { auth, firestore } from "../firebaseConfig";

import { GoogleAuthProvider, createUserWithEmailAndPassword, signInWithEmailAndPassword, isSignInWithEmailLink, sendPasswordResetEmail, sendSignInLinkToEmail, signInWithEmailLink, signInWithPopup, signOut } from "firebase/auth";
import { setHomes, setMenu, setProperties, setSignOut, setUser, setUserID } from "../redux/slice";
import moment from "moment";
import { addDoc, arrayUnion, collection, doc, getDoc, getDocs, query, setDoc, updateDoc, where } from "firebase/firestore";
import { useDispatch, useSelector } from "react-redux";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Logout, Visibility, VisibilityOff } from "@mui/icons-material";
import SignIn from "./SignIn";
import { Layout } from "./Layout";
import Box from "./Box";
import QuestionBank from "./QuestionBank";
import { useNavigate } from "react-router-dom";
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    // border: '1px solid #434343',
    // boxShadow: 24,
    p: 4,
};
const guestStyle = {
    position: 'absolute',
    top: '30%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    height: 200,
    bgcolor: 'background.paper',
    border: '1px solid #434343',
    boxShadow: 24,
    p: 4,
};
export const Account = () => {
    document.title = 'My homebound Account'
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate()
    const handleClickShowPassword = () => setShowPassword((show) => !show);


    const [isSignIn, setIsSignIn] = useState(false)
    const [firstName, setFirstName] = useState('')
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [open, setOpen] = useState(false);
    const [guestOpen, setGuestOpen] = useState(false)
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const dispatch = useDispatch();
    const authState = useSelector((state) => state);


    const { homes = [], user, userID } = authState

    const [guestName, setName] = useState('')
    const [guestEmail, setGuestEmail] = useState('')

    const [deleteName, setDeleteName] = useState(null)
    const [deleteID, setDeleteID] = useState(null)

    const [deleteOpen, setDeleteOpen] = useState(false)
    const [deleteHome, setDeleteHome] = useState(null)
    const showGuestModal = () => setGuestOpen(true)
    const confirmDelete = (guest, home) => {
        if (home.owner !== guest.id) {
            setDeleteOpen(true)
            setDeleteHome(home)
            setDeleteName(guest.name)
            setDeleteID(guest.email)
        }


    }

    const removeGuest = async () => {




        var currHome = _.find(homes, h => h.id == deleteHome.id)
        const updatedGuests = _.find(currHome.guests, h => h.email == deleteID)

        console.log(currHome, updatedGuests, deleteID,)
        const ref = doc(firestore, "homes", currHome.id);
        await updateDoc(ref, {
            guests: arrayRemove(updatedGuests)
        })

        closeDelete()
        getHomeGuests()




    }
    const closeDelete = () => {

        setDeleteOpen(false)
    }
    const addGuest = async () => {

        var homeID = null
        if (homes.length == 0) {
            const homeGuest = {
                owner: userID,
                guests: [
                    {
                        email: user.email,
                        id: userID,
                        name: user.name
                    },
                    {
                        name: guestName, email: guestEmail,

                    }],
                guestIDs: [userID]
            }
            const docRef = await addDoc(collection(firestore, "homes"), homeGuest);
            homeID = docRef.id

        }
        else {
            const ref = doc(firestore, "homes", homes[0].id);
            const result = await updateDoc(ref, {
                guests: arrayUnion({
                    name: guestName, email: guestEmail,
                })
            })
            homeID = homes[0].id
        }
        setGuestOpen(false)


        if (homeID) {


            const docRef = addDoc(collection(firestore, "emails"), {

                to:
                    [
                        {
                            email: guestEmail
                        }
                    ],
                personalization: [{
                    "email": guestEmail,
                    "data": {

                        "account_name": user.email,
                        "support_email": "yash@homebound.site",
                        invite: {
                            link: "https://homebound.site/invite/" + homeID
                        }
                    }
                }]

                ,
                "template_id": "3z0vklor80vl7qrx"
            });
        }
        setName("")

        setGuestEmail('')
        getHomeGuests()
    }

    const getHomeGuests = async () => {
        const q = query(collection(firestore, "homes"), where("guestIDs", "array-contains", userID));

        const querySnapshot = await getDocs(q)

        var tempGuests = []
        querySnapshot.forEach((doc) => {

            tempGuests.push({
                id: doc.id,
                ...doc.data()
            })
        })

        dispatch(setHomes(tempGuests))



    }



    useEffect(() => {

        getHomeGuests()
        if (isSignInWithEmailLink(auth, window.location.href)) {
            // Additional state parameters can also be passed via URL.
            // This can be used to continue the user's intended action before triggering
            // the sign-in operation.
            // Get the email if available. This should be available if the user completes
            // the flow on the same device where they started it.
            let email = window.localStorage.getItem('emailForSignIn');
            if (!email) {
                // User opened the link on a different device. To prevent session fixation
                // attacks, ask the user to provide the associated email again. For example:
                email = window.prompt('Please provide your email for confirmation');
            }
            // The client SDK will parse the code from the link for you.
            signInWithEmailLink(auth, email, window.location.href)
                .then((result) => {
                    // Clear email from storage.
                    window.localStorage.removeItem('emailForSignIn');
                    // You can access the new user via result.user
                    // Additional user info profile not available via:
                    // result.additionalUserInfo.profile == null
                    // You can check if the user is new or existing:
                    // result.additionalUserInfo.isNewUser
                })
                .catch((error) => {
                    // Some error occurred, you can inspect the code: error.code
                    // Common errors could be invalid email and invalid or expired OTPs.
                });
        }
    }, [])

    // const handleEmailPasswordSignIn = async () => {


    //     if (isSignIn) {


    //         getUser()
    //     }
    //     else {
    //         try {

    //             const actionCodeSettings = {
    //                 // URL you want to redirect back to. The domain (www.example.com) for this
    //                 // URL must be in the authorized domains list in the Firebase Console.
    //                 url: 'https://homebound.site',
    //                 // This must be true.
    //                 handleCodeInApp: true,

    //                 dynamicLinkDomain: 'https://homebound.site/auth'
    //             };



    //             createUserWithEmailAndPassword(auth, email, password)
    //                 .then((userCredential) => {
    //                     // Signed up 
    //                     const user = userCredential.user;

    //                     saveUser(user.uid)
    //                     // ...
    //                 })
    //                 .catch((error) => {
    //                     const errorCode = error.code;
    //                     const errorMessage = error.message;
    //                     console.log(errorMessage)



    //                     // ..
    //                 });

    //             sendSignInLinkToEmail(auth, email, actionCodeSettings)
    //                 .then(() => {
    //                     // The link was successfully sent. Inform the user.
    //                     // Save the email locally so you don't need to ask the user for it again
    //                     // if they open the link on the same device.
    //                     window.localStorage.setItem('emailForSignIn', email);
    //                     // ...
    //                 })
    //                 .catch((error) => {
    //                     const errorCode = error.code;
    //                     const errorMessage = error.message;

    //                     console.log(errorMessage)
    //                     // ...
    //                 });



    //         } catch (error) {
    //             setError(error.message);
    //         }
    //     }
    // };




    const reset = () => {

        if (email && email.length > 4) {
            sendPasswordResetEmail(auth, email).then(res => {


                setError("Check your inbox/spam folder for our email")
            }).catch(err => {
                setError("Unable to send email.")

            })
        }
        else {
            setError("Please add your email")
        }

    }
    const handleEmailPasswordSignIn = async () => {


        if (isSignIn) {



            signInWithEmailAndPassword(auth, email, password).then(res => {


                
                getUser(res.user.uid)

                navigate("/properties")
            })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    console.log(errorMessage)



                    if (errorMessage == "auth/email-already-in-use") {
                        setError("It looks like you already have an account")
                    }
                    else if (errorMessage == "auth/invalid-credential" || errorMessage == "auth/invalid-email" || errorMessage == "auth/wrong-password") {
                        setError("Your email or password is invalid")
                    }
                    else {
                        setError("Unable to sign in. Please check your credentials again")
                    }
                })
        }
        else {
            try {





                createUserWithEmailAndPassword(auth, email, password)
                    .then((userCredential) => {
                        // Signed up 
                        const user = userCredential.user;

                        saveUser(user.uid)
                        navigate("/guide")
                        // ...
                    })
                    .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;



                        if (errorMessage == "auth/email-already-in-use") {
                            setError("It looks like you already have an account")
                        }
                        else if (errorMessage == "auth/invalid-email" || errorMessage == "auth/wrong-password") {
                            setError("Your email or password is invalid")
                        }
                        else {
                            setError("Unable to sign in. Please check your credentials again")
                        }




                        // ..
                    });

                // sendSignInLinkToEmail(auth, email, actionCodeSettings)
                //     .then(() => {
                //         // The link was successfully sent. Inform the user.
                //         // Save the email locally so you don't need to ask the user for it again
                //         // if they open the link on the same device.
                //         window.localStorage.setItem('emailForSignIn', email);
                //         // ...
                //     })
                //     .catch((error) => {
                //         const errorCode = error.code;
                //         const errorMessage = error.message;

                //         console.log(errorMessage)
                //         // ...
                //     });


                // You can redirect the user or perform any action upon successful login
            } catch (error) {
                setError(error.message);
            }
        }
    };


    const getUser = (userID) => {


        const ref = doc(firestore, "users", userID);

        getDoc(ref).then(res => {

            const user = { id: res.id, ...res.data() }
            handleClose()
            dispatch(setUser(user))
        })
    }

    const saveUser = (userID) => {

        const tempUser = {
            id: userID,
            email: email,
            name: firstName,
            dateCreated: moment().toDate(),
            platformCreation: "web"
        }

        setDoc(doc(firestore, "users", userID), tempUser).then(res => {


            dispatch(setUser(tempUser))
        })

    }
    const handleGoogleSignIn = async () => {
        try {
            const provider = new GoogleAuthProvider();
            await signInWithPopup(auth, provider);
            // You can redirect the user or perform any action upon successful login
        } catch (error) {
            setError(error.message);
        }
    };


    const confirmSignOut = () => {
        setConfirmSignOutOpen(true)

    }

    const [confirmSignOutOpen, setConfirmSignOutOpen] = useState(false)

    const handleSignOut = () => {


        signOut(auth).then(res => {
            dispatch(setMenu("propertyDetails"))
            dispatch(setUser(null))
            dispatch(setUserID(null))
            dispatch(setProperties([]))

        })

        dispatch(setUser(null))
        dispatch(setUserID(null))
        dispatch(setProperties([]))
        dispatch(setSignOut())
        confirmSignOutHandleClose()
    }
    const confirmSignOutHandleClose = () => {

        setConfirmSignOutOpen(false)

    }

    return (

        <Layout >
            <Modal
                open={guestOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={guestStyle}
                >
                    <Box
                    // sx={{ background: "white", position: "absolute", padding: 2, width: "100%", zIndex: 19, }}
                    >
                        <Box sx={{ flexDirection: "column" }}>

                            <Typography variant='h4'>
                                New Member
                            </Typography>
                            <Box>
                                <TextField
                                    sx={{ mb: 1 }}
                                    label={"Name"}

                                    onChange={({ target }) => setName(target.value)}
                                    value={guestName}

                                />
                                <TextField
                                    sx={{ mb: 1 }}
                                    label={"Email"}

                                    onChange={({ target }) => setGuestEmail(target.value)}
                                    value={guestEmail}

                                />
                            </Box>
                            <Box row spaceBetween>
                                <Button onClick={




                                    () => setGuestOpen(false)
                                }>Cancel</Button>
                                <Button onClick={




                                    addGuest
                                }>Save</Button>
                            </Box>

                        </Box>

                    </Box>
                </Box>
            </Modal>
            <Dialog
                open={confirmSignOutOpen}
                onClose={confirmSignOutHandleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"Do you want to sign out"}
                </DialogTitle>
                <DialogContent>

                </DialogContent>
                <DialogActions>
                    <Button onClick={confirmSignOutHandleClose} autoFocus>No</Button>
                    <Button onClick={handleSignOut} >
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ height: "680px", }}>


                {authState.user && <Box sx={{
                    p: 2,
                    width: "100%"

                    // width: "30px", height: "30px",

                    // borderRadius: "15px", background: "white", display: "flex", justifyContent: "center", alignItems: "center" 
                }}
                >

                    <Box row>


                        <Box sx={{ width: "48%" }} >


                            <Typography variant="subtitle2" >
                                Name
                            </Typography>
                            <Typography variant="h6">
                                {user.name}
                            </Typography>

                            <Typography variant="subtitle2">
                                Email
                            </Typography>
                            <Typography variant="h6" >
                                {user.email}
                            </Typography>


                            <Typography variant="subtitle2">
                                Account Type
                            </Typography>

                            <Box row sx={{ width: "100%", }}>
                                <Typography variant="h6" >
                                    {user.premium ? "Premium" : "Standard"}
                                </Typography>

                                {!user.premium && <Button
                                    onClick={() => window.open("https://homebound.site/upgrade")}
                                    variant="text"
                                    color="primary"
                                    size="small"
                                    sx={{
                                        textTransform: "capitalize"
                                    }}

                                >
                                    Upgrade
                                </Button>}
                            </Box>







                        </Box>
                        <Box sx={{ width: "48%" }}>
                            <Box row spaceBetween>
                                <Typography variant='h3'>
                                    My Home
                                </Typography>
                                {<Button

                                    sx={{ textTransform: "capitalize" }}
                                    onClick={showGuestModal}>
                                    Add Guest
                                </Button>}
                            </Box>
                            <Box sx={{ maxHeight: "600px", overflowY: 'scroll' }}>

                                {homes.map(h => {
                                    return (
                                        // eslint-disable-next-line react/jsx-key
                                        <List dense

                                        >

                                            {h.guests.map((g, i) => {


                                                return (

                                                    <ListItem key={i} >
                                                        <ListItemButton

                                                            onClick={() => {

                                                                confirmDelete(g, h)
                                                            }}
                                                        >
                                                            <ListItemText
                                                                primary={g.name}
                                                                secondary={
                                                                    <Box row>
                                                                        {g.email}
                                                                        {h.owner == g.id &&

                                                                            <Chip
                                                                                sx={{ width: "60px" }}
                                                                                label="Admin"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                color="primary"
                                                                                clickable

                                                                            />}
                                                                    </Box>}

                                                            />
                                                        </ListItemButton>

                                                    </ListItem>
                                                )

                                            })
                                            }

                                        </List>
                                    )
                                })}
                            </Box>

                        </Box>

                    </Box>
                    <Box>
                        <Button variant=''></Button>
                    </Box>
                    <Box sx={{ mt: 2, width: "48%" }} >
                        <QuestionBank />
                    </Box>
                    {/* <IconButton onClick={confirmSignOut}>
                        <Typography sx={{ fontSize: "22px" }} variant="h6">

                            {authState.user.name[0].toUpperCase()}
                        </Typography>
                    </IconButton> */}
                    <Button
                        onClick={confirmSignOut}

                    > Sign Out</Button>
                </Box>}
                {/* {!authState.user && <Box> <Button variant="contained" onClick={handleOpen} sx={{ textTransform: "none" }}>Sign In</Button></Box>} */}



                <SignIn open={open} handleClose={handleClose} />

                {!user && <Box

                    sx={style}
                >

                    <Box >
                        <Grid container spacing={2} justifyContent="center" direction="row" style={{ minHeight: '50vh', mx: 3, }}>

                            <Grid item xs={8} md={10}>
                                <Typography sx={{ mt: 3 }} variant="h4" gutterBottom>{isSignIn ? "Log in" : "Sign up"} to Homebound</Typography>


                            </Grid>
                            <Grid item xs={10} md={10}>


                                {
                                    !isSignIn && <TextField

                                        label="Name"
                                        variant="outlined"
                                        value={firstName}
                                        onChange={(e) => setFirstName(e.target.value)}
                                        fullWidth
                                    />}

                            </Grid>
                            <Grid item md={10}>


                                <TextField
                                    type="email"
                                    label="Email"
                                    variant="outlined"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item md={10}>
                                <TextField
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    variant="outlined"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    fullWidth
                                    // InputProps={{
                                    // endAdornment:{
                                    //     <InputAdornment position="end">
                                    //         <IconButton
                                    //             aria-label="toggle password visibility"
                                    //             onClick={handleClickShowPassword}
                                    //             // onMouseDown={handleMouseDownPassword}
                                    //             edge="end"
                                    //         >
                                    //             {showPassword ? <VisibilityOff /> : <Visibility />}
                                    //         </IconButton>
                                    //     </InputAdornment>
                                    // }}
                                    InputProps={{
                                        endAdornment:
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    // onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                    }}

                                    onKeyDown={
                                        (ev) => {

                                            if (ev.key === 'Enter') {
                                                // Do code here
                                                handleEmailPasswordSignIn()
                                            }
                                        }
                                    }
                                />
                            </Grid>
                            <Grid item md={10}>
                                <Box
                                    sx={{ alignItems: "center", width: "100%", }}
                                >
                                    <Button variant="contained" sx={{ width: "100px" }} color="primary" onClick={handleEmailPasswordSignIn} >
                                        {isSignIn ? "Login" : "Sign Up"}

                                    </Button>
                                </Box>
                            </Grid>
                            {/* <Grid item>
                    <Typography variant="h4" gutterBottom>Login with Google</Typography>
                    
                </Grid> */}
                            {<Grid item md={12} center sx={{ justifyContent: "center", flexDirection: "row", display: "row" }}>

                                <Box
                                    sx={{ alignItems: "center", width: "100%", }}
                                >

                                    <Button variant="text" color="primary"
                                        sx={{ textTransform: "none" }}
                                        // onClick={handleGoogleSignIn} 
                                        onClick={() => setIsSignIn(prev => !prev)}
                                    >
                                        {!isSignIn ? "Already have an account?" : "Create an account"}
                                    </Button>
                                </Box>
                            </Grid>
                            }
                            {isSignIn && <Grid item md={12} center>
                                <Box
                                    sx={{ alignItems: "center", width: "100%", }}
                                >
                                    <Button variant="text" color="primary"
                                        sx={{ textTransform: "none" }}
                                        // onClick={handleGoogleSignIn} 
                                        onClick={reset}
                                    >
                                        Reset Password
                                    </Button>
                                </Box>
                            </Grid>
                            }
                            {error && (
                                <Grid item md={10}>
                                    <Typography variant="body1" color="error" align="center">
                                        {error}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>


                    </Box>
                </Box>}
                {/* <Button onClick={handleOpen} sx={{ textTransform: "none" }}>Sign In</Button> */}

            </Box>
        </Layout>



    )
    return (
        <Box>

            {authState.user && <Box sx={{ width: "70px", height: "70px", borderRadius: "70px", background: "red" }}></Box>}
            {!authState.user && <Box> <Button onClick={handleOpen} sx={{ textTransform: "none" }}>Sign In</Button></Box>}


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Grid container spacing={2} justifyContent="center" direction="column" style={{ minHeight: '50vh' }}>

                        <Grid item xs={8}>
                            <Typography variant="h4" gutterBottom>Login with Email/Password</Typography>


                        </Grid>
                        {
                            !isSignIn && <Grid item xs={10}>


                                <TextField

                                    label="Name"
                                    variant="outlined"
                                    value={firstName}
                                    onChange={(e) => setFirstName(e.target.value)}
                                    fullWidth
                                />

                            </Grid>}
                        <Grid item>


                            <TextField
                                type="email"
                                label="Email"
                                variant="outlined"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <TextField
                                type="password"
                                label="Password"
                                variant="outlined"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={handleEmailPasswordSignIn} fullWidth>
                                Login
                            </Button>
                        </Grid>
                        {/* <Grid item>
                            <Typography variant="h4" gutterBottom>Login with Google</Typography>
                            <Button variant="contained" color="primary" onClick={handleGoogleSignIn} fullWidth>
                                Sign in with Google
                            </Button>
                        </Grid> */}
                        {error && (
                            <Grid item>
                                <Typography variant="body1" color="error" align="center">
                                    {error}
                                </Typography>
                            </Grid>
                        )}
                    </Grid>


                </Box>
            </Modal>


        </Box>


    )
}