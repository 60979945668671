import { Box as MUIBox } from "@mui/material"

const Box = (props) => {

    const { flex, row = false, children, spaceBetween, sx } = props
    return <MUIBox
        sx={{
            display: "flex",
            flexDirection: row ? "row" : "column",
            justifyContent: spaceBetween ? "space-between" : spaceBetween,
            ...sx

        }}
    >{children}</MUIBox>
}


export default Box