import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { setMenu, setProperties, setProperty, setSnackMessage } from "../redux/slice";
import Box from "@mui/material/Box";
import { doc, where, query, getDocs, collection, updateDoc } from "firebase/firestore";
import { firestore } from "../firebaseConfig";
import {
    Button, Card, CardActionArea,

    CardContent,
    CardActions, IconButton, TextField, Typography, Grid,
    ButtonBase,
    styled,
    CardMedia
} from '@mui/material';
import { CustomIcons } from './Score';
import { Delete, CopyAllOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import zooplaLogo from "../assets/img/zooplaLogo.png";
import rightmoveLogo from "../assets/img/rightmoveLogo.png";
const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 200,
    [theme.breakpoints.up('sm')]: {
        width: '100% !important', // Overrides inline-style
        height: 50,
    },

}));
const PropertyGrid = ({ propertyDetails, onClose }) => {
    document.title = 'My Properties'
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const authState = useSelector((state) => state);
    var {
        userID,
        properties = []
    } = authState;
    // properties = []
    const [openShare, setOpenShare] = useState(false);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [propID, setPropID] = useState("");

    useEffect(() => {
        getProperties();
    }, []);

    const getProperties = async () => {
        const q = query(collection(firestore, "properties"), where("users", "array-contains", userID), where("active", "==", true));
        const querySnapshot = await getDocs(q);

        let tempProperties = [];
        querySnapshot.forEach((doc) => {
            tempProperties.push({ id: doc.id, ...doc.data() });
        });
        dispatch(setProperties(tempProperties));
    };

    const invite = () => {
        console.log("share to", name, email);
        setName("");
        setEmail("");
        setOpenShare(false);
    };

    const deleteProp = async (id) => {
        const ref = doc(firestore, "properties", id);
        await updateDoc(ref, { active: false });
        window.location.reload();
    };

    return (
        <Box sx={{ width: "100%", p: 2, }}>
            <Typography variant='h4' sx={{ paddingTop: 2, paddingBottom: 2 }}>
                My Properties
            </Typography>

            {openShare && (
                <Box sx={{ background: "white", position: "absolute", width: "90%", zIndex: 19 }}>
                    <Typography variant='h4'>Share with a friend or flatmate?</Typography>
                    <TextField sx={{ mt: 3, mx: 2 }} label={"Name"} onChange={({ target }) => setName(target.value)} value={name} />
                    <TextField sx={{ mt: 3, mx: 2 }} label={"Email"} onChange={({ target }) => setEmail(target.value)} value={email} />
                    <Button sx={{ mt: 3, mx: 2 }} variant='contained' onClick={invite}>Invite</Button>
                    {name && <Typography sx={{ fontSize: "15px" }} variant='caption'>{name} will be able to view and edit all details about this property</Typography>}
                </Box>
            )}

            <Box sx={{ minHeight: "420px", height: "80%", width: "94%", background: openShare ? "rgba(0, 0, 0, 0.4)" : null }}>
                <Grid container spacing={2}>
                    {properties.map(prop => (
                        <Grid item xs={12} sm={6} md={4} key={prop.id}>
                            <Card>
                                <CardActionArea onClick={() => {
                                    dispatch(setMenu("propertyDetails"));
                                    dispatch(setProperty(prop));
                                    navigate("/properties/" + prop.id,);
                                    window.location.reload();

                                }}>
                                    <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                                        <img src={prop.propertyImage} width={80} height={80} alt={prop.propertyTitle} />
                                        <Box sx={{ ml: 1 }}>
                                            <Typography>{prop.propertyTitle}</Typography>
                                            <Typography>{prop.propertyPrice}</Typography>
                                        </Box>
                                    </Box>
                                </CardActionArea>
                                <Box sx={{ display: 'flex', alignItems: 'center', p: 2 }}>
                                    {prop.score > 0 && prop.score <= 5 && <CustomIcons score={prop.score} fontSize={28} returnIcon={true} />}
                                    <CopyToClipboard text={'https://homebound.site/' + prop.id} onCopy={() => dispatch(setSnackMessage("Link Copied!"))}>
                                        <IconButton size='small'><CopyAllOutlined /></IconButton>
                                    </CopyToClipboard>
                                    <IconButton size='small' onClick={() => deleteProp(prop.id)}><Delete /></IconButton>
                                </Box>
                            </Card>
                        </Grid>
                    ))}
                    {/* {properties.length == 0 &&

                        <Box
                            sx={{ height: "400px" }}
                        >
                            <Card
                                sx={{
                                    //     background : 'red'
                                    // ,
                                    //     width : "100%"
                                }}
                            >
                                <Grid md={6}>
                                    <CardActionArea
                                    

                                    onClick={() => window.open("https://zoopla.co.uk", "_blank")}
                                    >



                                        <CardMedia
                                            component="img"
                                            height="140"
                                            width={"820"}
                                            image={zooplaLogo}
                                            alt="green iguana"
                                            sx = {{}}
                                        />
                                     
                                    </CardActionArea>
                                 <Box row sx={{ mt: 2, mb: 2, display: "flex", justifyContent: "center" }}>
                                        <CardActions sx={{ display: "flex", justifyContent: "center" }}>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '70%' }}>
                                                <ImageButton

                         
                                                    focusRipple
                                                    key={zooplaLogo}
                                                    style={{
                                                        width: "320px",
                                                    }}
                                                >
                                                    <img src={zooplaLogo} width={"120px"} />



                                                </ImageButton>

                                            </div>

                                        </CardActions>

                                        <CardActions>
                                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                                <ImageButton
                                                    focusRipple
                                                    onClick={() => window.open("https://rightmove.co.uk", "_blank")}
                                                    key={zooplaLogo}
                                                    style={{
                                                        width: "320px",
                                                    }}
                                                >
                                                    <img src={rightmoveLogo} width={"220px"} />



                                                </ImageButton>

                                            </div>

                                        </CardActions>
                                    </Box> 
                                </Grid>
                                <CardContent sx={{


                                }}>


                                    <Typography variant='body2' >
                                        Choose a property website to begin
                                    </Typography>
                                </CardContent>


                            </Card>
                        </Box>

                    } */}
                </Grid>
            </Box>
        </Box>
    );
};

export default PropertyGrid;
