import * as React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { Box, Typography } from '@mui/material';

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: theme.palette.action.disabled,
  },
}));

export const CustomIcons = ({score, fontSize = 45, returnIcon = true}) => {
  console.log("tetetetetet",score)
  switch (score) {
    case 1:
      return returnIcon 
        ? <SentimentVeryDissatisfiedIcon color="error" sx={{ fontSize: fontSize }} />
        : 'Very Dissatisfied';
    case 2:
      return returnIcon 
        ? <SentimentDissatisfiedIcon color="error" sx={{ fontSize: fontSize }} />
        : 'Dissatisfied';
    case 3:
      return returnIcon 
        ? <SentimentSatisfiedIcon color="warning" sx={{ fontSize: fontSize }} />
        : 'Neutral';
    case 4:
      return returnIcon 
        ? <SentimentSatisfiedAltIcon color="success" sx={{ fontSize: fontSize }} />
        : 'Satisfied';
    case 5:
      return returnIcon 
        ? <SentimentVerySatisfiedIcon color="success" sx={{ fontSize: fontSize }} />
        : 'Very Satisfied';
    default:
      return null;
  }
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span  {...other}>
    
    
    <CustomIcons score = {value} returnIcon = {true}/></span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function Score({
    value,
    onChange
}) {

  return (
    <Box sx = {{width : "60%",  }}>
      <Typography variant='h3' >
        Score
      </Typography>
    
    <StyledRating
    sx = {{width : "100%", justifyContent : "space-between"}}
    value={value}
    max={5}
      name="highlight-selected-only"
      // defaultValue={2}
      IconContainerComponent={IconContainer}
      getLabelText={(value) => <CustomIcons score = {value} fontSize = {28} returnIcon = {true}/>}
      highlightSelectedOnly
      onChange={(event, newValue) => {
        onChange(newValue);
      }}
    />
    </Box>
  );
}
