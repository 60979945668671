import React, { useState } from 'react';

import { Chip, Typography } from "@mui/material"
import Box from "./Box"
import _ from "lodash";
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { doc,  updateDoc } from "firebase/firestore";
import { firestore } from '../firebaseConfig';


const QuestionBank = (
   
) => {

    const authState = useSelector((state) => state);

    const {

        homes
    } = authState
    var home = {}
    if (homes.length == 1) {
        home = homes[0]
    }
    else if (homes.length > 1) {
        home = homes[0]

    }

   


    const [locQuestions, setLocQuestions] = useState(home && home.questions ? home.questions : [])
  
    // useEffect(() => {

    // }, [questions])



    const updateQuestions = async (questions) => {
        setLocQuestions(questions)
        const ref = doc(firestore, "homes", home.id);
         await updateDoc(ref, {
            questions
        })
    }
    const onSelect = (question) => {


        if (_.find(locQuestions, s => s.id == question.id)) {
            var tempQuestions = _.filter(locQuestions, s => s.id !== question.id)
            updateQuestions(tempQuestions)
        }
        else {
            updateQuestions([...locQuestions, question])
        }
    }
    return (
        <Box
            sx={{
                pb: 6

            }}>

            <Typography variant='h3'>
                Available Questions
            </Typography>

            <Box sx={{  display: "block" }} >


                {baseQuestions.map(q => {
                    const exist = _.find(locQuestions, ans => ans.id == q.id)
console.log(exist)
                    return <Chip label={q.text}
                        key={q.id}
                        variant={exist ? "contained" : "outlined"}
                        sx={{ m: "3px" }}
                        color="primary"
                        clickable
                        onClick={() => {

                            onSelect(q)

                        }
                        }
                    />

                })}
            </Box>
        </Box>
    )
}




const baseQuestions = [{
    intention: "rent",
    id: 1,
    text: "Are bills included",
    type: "checkbox"
},
{
    intention: "rent",
    id: 2,
    text: "Can I decorate",
    type: "checkbox"
},
{
    intention: "rent",
    id: 3,
    text: "Is it pet friendly",
    type: "checkbox"
},
{

    id: 4,
    text: "What is the internet speed",
    type: "textfield"
},
{
    intention: "buy",
    id: 5,
    text: "Is the seller negiotable?",
    type: "textfield"
},
{
    intention: "buy",
    id: 6,
    text: "Why is the owner selling?",
    type: "textfield"
},
{
    intention: "buy",
    id: 7,
    text: "Is there an onward chain",
    type: "checkbox"
},
{
    intention: "buy",
    id: 8,
    text: "How old is the property?",
    type: "textfield"
},
{
    id: 9,
    text: "Have the sellers had any offers",
    type: "checkbox"
}


]

export default QuestionBank