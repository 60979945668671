import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux"
import { setMenu, setProperties, setProperty, setSnackMessage } from "../redux/slice"
import Box from "./Box"
import { doc, where, query, getDocs, collection, updateDoc, addDoc } from "firebase/firestore";
import { firestore } from "../firebaseConfig";
import { Button, Card, CardActionArea, IconButton, SwipeableDrawer, TextField, Typography } from '@mui/material';
import { CustomIcons } from './Score';
import { Share, Delete, CopyAllOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Global } from '@emotion/react';

const ViewProperties = ({
    propertyDetails,
    onClose
}) => {

    const navigator = useNavigate()
    const dispatch = useDispatch()
    const authState = useSelector((state) => state);
    const drawerBleeding = 56;
    const {
        menu = "propertyDetails",
        user,
        userID,
        properties = []
    } = authState

    const toggleDrawer = (newOpen) => () => {
        setOpen(newOpen);





    };
    const [open, setOpen] = useState(false)

    useEffect(() => {


        getProperties()
    }, [])

    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [openShare, setOpenShare] = useState(false)
    const [propID, setPropID] = useState("")
    const invite = () => {

        console.log("share to", name, email)
        setName("")
        setEmail("")

        setOpenShare(false)



    }

    const deleteProp = async (id) => {

        const ref = doc(firestore, "properties", id);
        const savedRef = await updateDoc(ref, {
            active: false
        })
        window.location.reload();

    }
    const getProperties = async () => {



        const q = query(collection(firestore, "properties"), where("users", "array-contains", userID), where("active", "==", true));

        const querySnapshot = await getDocs(q)

        var tempProperties = []
        querySnapshot.forEach((doc) => {

            tempProperties.push({
                id: doc.id,
                ...doc.data()
            })
        })
        dispatch(setProperties(tempProperties))


    }

    return (
        <Box


            sx={{ width: "100%", }}
        >

            {/* <ShareSocial
                url="url_to_share.com"
                socialTypes={['facebook', 'twitter', 'reddit', 'linkedin']}
            /> */}
            <Typography
                variant='h4'
                sx={{
                    paddingTop: 2,
                    paddingBottom: 2
                }}
            >
                My Properties
            </Typography>

            {openShare && <Box
                sx={{ background: "white", position: "absolute", width: "98%", zIndex: 19, }}
            >
                <Box >

                    <Typography variant='h4'>

                        Share with a friend or flatmate?
                    </Typography>
                    <TextField
                        sx={{ mt: 3, mx: 2 }}
                        label={"Name"}

                        onChange={({ target }) => setName(target.value)}
                        value={name}

                    />
                    <TextField
                        sx={{ mt: 3, mx: 2, }}
                        label={"Email"}

                        onChange={({ target }) => setEmail(target.value)}
                        value={email}

                    />

                    <Button
                        sx={{ mt: 3, mx: 2, }}

                        variant='contained' onClick={() => {

                            invite()

                        }}>Invite</Button>

                    {name && <Typography sx={{ fontSize: "15px" }} variant='caption'>{name} will be able to view and edit all details about this property</Typography>}

                </Box>

            </Box>}
            <Box sx={{
                height: "100%",
                width: "100%",
                background: openShare ? "rgba(0, 0, 0, 0.4)" : null
            }}
            >


                {/* New Code */}





                {properties.map(prop => {


                    return (
                        <Card key={prop.id}


                        >
                            <Box
                                row
                            >
                                <CardActionArea onClick={() => {
                                    dispatch(setMenu("propertyDetails"))
                                    dispatch(setProperty(prop))


                                    navigator("/properties/" + prop.id, {
                               
                                    
                                    })
                                    window.location.reload()
                                    // window.history.replaceState(null, "New Page Title", prop.id)
                                    onClose()
                                }}
                                // sx={{ width: "95%" }}
                                >
                                    <Box row>
                                        <img src={prop.propertyImage} width={80} height={80} />

                                        <Box sx={{ ml: 1 }}>
                                            <Typography>
                                                {prop.propertyTitle}
                                            </Typography>

                                            <Box row>
                                                <Typography>
                                                    {prop.propertyPrice}
                                                </Typography>

                                            </Box>

                                        </Box>


                                    </Box>
                                </CardActionArea>
                                <Box
                                    sx={{ alignItems: "center" }}
                                >
                                    {prop.score > 0 && prop.score <= 5 && <CustomIcons score = {prop.score} fontSize = {28} returnIcon = {true}/>}
                                    <Box row>

                                        <CopyToClipboard text={'https://homebound.site/' + prop.id}
                                            onCopy={() => { console.log("copied") }}>


                                            <IconButton size='small' variant onClick={() => {
                                                // setOpenShare(true)
                                                setPropID(prop.id)
                                                dispatch(setSnackMessage("Link Copied!"))

                                            }}><CopyAllOutlined /></IconButton>
                                        </CopyToClipboard>
                                        <IconButton size='small' onClick={() => {
                                            deleteProp(prop.id)

                                        }}><Delete /></IconButton>
                                    </Box>
                                </Box>
                            </Box>
                        </Card>
                    )
                })}



                {/* New Code End */}
                {/* {properties.map(prop => {


                    return (
                        <Card key={prop.id}>
                            <Box
                                row
                            >
                                <CardActionArea onClick={() => {
                                    dispatch(setMenu("propertyDetails"))
                                    dispatch(setProperty(prop))


                                 
                                    // window.history.replaceState(null, "New Page Title", prop.id)
                                    onClose()
                                }}
                                    sx={{ width: "95%" }}
                                >
                                    <Box row>
                                        <img src={prop.propertyImage} width={80} height={80} />

                                        <Box sx={{ ml: 1 }}>
                                            <Typography>
                                                {prop.propertyTitle}
                                            </Typography>

                                            <Box row>
                                                <Typography>
                                                    {prop.propertyPrice}
                                                </Typography>
                                                {prop.score > 0 && prop.score < 5 && customIcons[prop.score].icon}
                                            </Box>

                                        </Box>


                                    </Box>
                                </CardActionArea>
                                <Box row
                                    sx={{ alignItems: "center" }}
                                >
                                    <CopyToClipboard text={'https://homebound.site/' + prop.id}
                                        onCopy={() => { console.log("copied") }}>


                                        <IconButton size='small' variant onClick={() => {
                                            // setOpenShare(true)
                                            setPropID(prop.id)
                                            dispatch(setSnackMessage("Link Copied!"))

                                        }}><CopyAllOutlined /></IconButton>
                                    </CopyToClipboard>
                                    <IconButton size='small' onClick={() => {
                                        deleteProp(prop.id)

                                    }}><Delete /></IconButton>
                                </Box>
                            </Box>
                        </Card>
                    )
                })} */}
            </Box>
        </Box>
    )

}


export default ViewProperties