/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import Box from "./Box";
import { Button, Chip, IconButton, Skeleton, Modal, TextField, Tooltip, Typography } from '@mui/material';
import Score from './Score';
import { UserNotes } from './UserNotes';
import Questions from './MyQuestions';
import { firestore } from "../firebaseConfig";
import { doc, where, query, getDocs, collection, addDoc, updateDoc, getDoc, arrayUnion } from "firebase/firestore";
import { ContactPhone, OtherHouses, Settings } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { setMenu, setProperties, setProperty, setSnackMessage } from '../redux/slice';
import _ from "lodash";
import moment from 'moment';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '1px solid #434343',
    boxShadow: 24,
    p: 4,
};
const PropertyDetails = ({
    shareCode
}) => {

    const authState = useSelector((state) => state);

    const {

        user,
        userID,
        property,
        home,
        homes

    } = authState
    var questions =  homes && homes.length > 0 ? homes[0].questions : [];

    const [propertyID, setPropertyID] = useState(null);
    const [propertyLink, setPropertyLink] = useState('');
    const [propertyImage, setPropertyImage] = useState('');
    const [source, setSource] = useState('');
    const [forSale, setForSale] = useState(false);
    const [propertyTitle, setPropertyTitle] = useState('');
    const [location, setLocation] = useState('');
    const [priceQualifier, setPriceQualifier] = useState('');
    const [propertyPrice, setPropertyPrice] = useState('');




    const [loading, setLoading] = useState(true)
    const [fetchedProperty, setFetchedProperty] = useState(null)
    const [score, setScore] = useState(0)
    const [notes, setNotes] = useState([])

    const [id, setID] = useState(null)
    const [answers, setAnswers] = useState([])
    const [showAllDetails, setShowAllDetails] = useState(true)

    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [agentsName, setAgentsName] = useState("")
    const [agentsPhone, setAgentsPhone] = useState("")


    const updateProperty = async (newData) => {

        const ref = doc(firestore, "properties", id);
        const savedRef = await updateDoc(ref, { dateUpdated: moment().toDate(), ...newData })



    }

    const checkPermissions = () => {


        if (user && property.users && property.users.includes(userID)) {
            return true

        }
        else if (!user) {
            dispatch(setSnackMessage("Sign In first"))
        }
        else if (!property.users.includes(userID)) {
            dispatch(setSnackMessage("You don't have permission to change this property"))
        }


    }
    const updateScore = (val) => {

        if (checkPermissions()) {
            if (userID == property.userID) {
                setShowAllDetails(true)
                setScore(val)
                updateProperty({ score })

            }
            else {
                console.log(userID, property)
                dispatch(setSnackMessage("You don't have permission to update the score"))
            }
        }


    }
    const addNote = async (value) => {

        if (checkPermissions()) {
            if (value) {
                const text = value


                setNotes([...notes, {
                    text,
                    userID,
                    name: user.name
                }])



                updateProperty({
                    notes: arrayUnion({
                        text,
                        userID,
                        name: user.name
                    })
                })



            }
        }


    }
    const dispatch = useDispatch()


    useEffect(() => {

        if (property) {
            // setScore(property.score)
            // setAnswers(property.answers)
            // setNotes(property.notes);
            // setID(property.id)

            // setShowAllDetails(true)
        }
        else {
            setScore(0)
            setAnswers([])
            setNotes([]);
            setID(null)

            // setShowAllDetails(false)
        }
    }, [property])

    useEffect(() => {


        getProperty()


    }, [])





    const getProperty = async () => {
        setLoading(true)
        setFetchedProperty(null)

        try {
            if (true) {

                const ref = doc(firestore, "properties", shareCode)


                getDoc(ref).then(res => {
                    const prop = { id: shareCode, ...res.data() }

                    if(
                        prop.score
                    )
                    {
                        document.title = "Score:" + prop.score + " | " +   prop.propertyTitle
                    }
                    else
                    {
                        document.title =  prop.propertyTitle

                    }
                    setShowAllDetails(true)
                    setFetchedProperty(prop)
                    dispatch(setProperty(prop))

                    setScore(prop.score)
                    setAnswers(prop.answers)
                    setNotes(prop.notes);
                    setID(prop.id)
                    setAgentsName(prop.agentsName)
                    setAgentsPhone(prop.agentsPhone)
                })

                // const q = query(collection(firestore, "properties"), where("shareCode", "==", shareCode));

                // const querySnapshot = await getDocs(q)
                // console.log(querySnapshot.docs)

                // var list = []

                // querySnapshot.forEach((doc) => {

                //     const prop = { id: doc.id, ...doc.data() }

                //     if (true) {
                //         console.log("found", prop.score, prop.notes, prop.id)
                //         setShowAllDetails(true)
                //         setFetchedProperty(prop)

                //         setScore(prop.score)
                //         setAnswers(prop.answers)
                //         setNotes(prop.notes);
                //         setID(prop.id)
                //         setAgentsName(prop.agentsName)
                //         setAgentsPhone(prop.agentsPhone)
                //     }
                //     else {

                //         console.log("prop", prop)
                //     }


                //     list.push(prop)

                // })
                setLoading(false)
                // dispatch(setProperties(list))


            }
        } catch (err) {
            console.log(err)
        }





    }
    const getProperties = async () => {



        // const ref = doc(firestore, "users", userID);

        // getDoc(ref).then(res => {

        //     const user = { id: res.id, ...res.data() }

        //     dispatch(setUser(user))
        // })

        if (propertyID) {

            // const q = query(collection(firestore, "properties"), where("users", "array-contains", userID));

            // const querySnapshot = await getDocs(q)

            // var properties = []
            // querySnapshot.forEach((doc) => {


            //     const prop = { id: doc.id, ...doc.data() }

            //     properties.push(prop)

            //     if (prop.propertyID == propertyID) {
            //         console.log("Found")
            //     }


            // })

            // console.log("proper", properties)

            // dispatch(setProperties(properties))
        }


    }
    const saveProperty = async () => {


        var tempProp = {
            propertyID,
            propertyLink,
            propertyImage,
            source,
            forSale,
            score,
            propertyTitle,
            location,
            priceQualifier: priceQualifier ? priceQualifier : null,
            propertyPrice,
            ...score,
            answers,
            // notes,
            users: [userID]
        }

        if (id) {
            tempProp.dateUpdated = moment().toDate()
            const ref = doc(firestore, "properties", id);
            const savedRef = await updateDoc(ref, tempProp)

            dispatch(setSnackMessage("Property Saved!"))
            // if (savedRef.id) {

            // }
            // else {
            //     dispatch(setSnackMessage("Property NOT Saved!"))
            // }

        }
        else {

            tempProp.dateAdded = moment().toDate()
            const docRef = await addDoc(collection(firestore, "properties"), tempProp);

            if (docRef.id) {
                dispatch(setSnackMessage("Property Saved!"))
            }
            else {
                dispatch(setSnackMessage("Unable to save"))
            }

        }








    }


    const onComplete = (questionID, value) => {


        var answer = {
            value, questionID,
        }
        var tempAnswers = _.cloneDeep(answers)
        var existQuestion = _.find(tempAnswers, a => a.questionID == questionID)

        if (existQuestion) {


            existQuestion.value = value

            setAnswers(tempAnswers)
        }
        else {
            tempAnswers = [...tempAnswers, answer]
            setAnswers(tempAnswers)
        }


        console.log(answers)
        // const ref = doc(firestore, "properties", id);
        // const savedRef = updateDoc(ref, { ...fetchedProperty, dateUpdated: moment().toDate(), answers: tempAnswers })
        updateProperty({ answers: tempAnswers })

    }


    const updateAgent = () => {



        var newData = {

        }

        if (agentsName) {
            newData.agentsName = agentsName
        }


        if (agentsPhone) {
            newData.agentsPhone = agentsPhone
        }
        const ref = doc(firestore, "properties", id);
        updateProperty(newData)


        setOpen(false)
    }


   
    return (
        <Box sx={{ overflowY: "scroll", mt: 0, }}>


            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>

                    <Box >

                        <Typography variant='h4'>
                            Agent&apos;s Details
                        </Typography>
                        <TextField
                            sx={{ p: 1 }}
                            label={"Name"}

                            onChange={({ target }) => setAgentsName(target.value)}
                            value={agentsName}

                        />
                        <TextField
                            sx={{ p: 1 }}
                            label={"Phone Number"}
                            onChange={({ target }) => setAgentsPhone(target.value)}
                            value={agentsPhone}

                        />
                        <Button onClick={updateAgent

                        }>Save</Button>
                    </Box>

                </Box>

            </Modal>

            {/* {!fetchedProperty && !loading && <Box sx={{ width: "100%", height: "400px" }}>

                <Typography> Select a property from your favourites</Typography>
            </Box>} */}
            {!fetchedProperty && loading && <Box>

                <Box

                >
                    <Skeleton width={"70%"}
                        height={600}

                    />
                    <Box sx={{ ml: 1, }}>

                        <Typography variant="h3" sx={{ paddingBottom: 0, minWidth: "150px" }}><Skeleton /></Typography>

                        <Box
                            row
                            sx={{ alignItems: "center", }}
                        >
                            <Typography sx={{ width: "120px" }}><Skeleton /></Typography>

                        </Box>

                    </Box>
                </Box>
            </Box>}
            {fetchedProperty &&

                <Box>
                    <Box sx={{ height: "700px", background: open ? "rgba(0, 0, 0, 0.4)" : null }} row>


                        <Box

                            sx={{ width: "70%", mr: 2 }}
                        >

                            <img src={fetchedProperty.propertyImage} style={{
                                width: "100%", height: 600
                                , overflow: "clip",
                                overflowClipMargin: "content-box",
                                borderTopLeftRadius: "20px"
                            }} />

                            {!property.active && <Box sx={{
                                position: "absolute",

                                width: "62%", height: "600px",
                                background: "#efefef",
                                opacity: "0.8",
                                justifyContent: "center",
                                alignItems: "center"
                            }}>

                                <Box sx={{}}>

                                    <Typography
                                        variant='h4'
                                    >
                                        This property is private
                                    </Typography>
                                    {/* {<Button variant='contained'>
                                        Recover
                                    </Button>} */}
                                </Box>
                            </Box>}
                            <Box sx={{ ml: 1, }}>

                                <Box row sx={{ alignItems: "baseline" }}>

                                    <Typography variant="h2" sx={{ paddingBottom: 0 }}>{fetchedProperty.propertyTitle}</Typography>

                                </Box>
                                <Box
                                    row
                                    sx={{ alignItems: "center", }}
                                >
                                    <Typography variant='h4'>{fetchedProperty.propertyPrice}</Typography>
                                    <Tooltip title={"View listing in " + fetchedProperty.source} placement='top'>

                                        <Chip size="small" sx={{ ml: 1, width: "100px" }} color='primary' variant="outlined" label={fetchedProperty.source} onClick={() =>
                                            window.open(fetchedProperty.propertyLink, "_blank")
                                        } />
                                    </Tooltip>

                                </Box>

                                {fetchedProperty.agentsName &&
                                    <Typography>
                                        Agent's name : {fetchedProperty.agentsName}
                                    </Typography>
                                }
                                {fetchedProperty.viewingDate &&
                                    <Typography>
                                        FollowupDate : {fetchedProperty.viewingDate}
                                    </Typography>
                                }
                                <Tooltip placement='top' title="Agent's Details">
                                    <IconButton sx={{ width: "30px" }} onClick={setOpen} size='small' >
                                        <ContactPhone color={fetchedProperty.agentsName && fetchedProperty.agentsPhone ? 'primary' : "info"} />
                                    </IconButton>
                                </Tooltip>

                            </Box>
                        </Box>

                        <Box

                            sx={{
                                width: "40%",
                                // background : "red",
                                alignItems: "center"
                            }}
                        >

                            <Score


                                value={score}
                                onChange={updateScore} />
                            {showAllDetails && <UserNotes addNote={addNote} notes={notes} userID={userID} />}
                        </Box>

                    </Box>
                    <Box sx={{
                        ml: 1,
                        mt: 5
                    }}>

                        <Questions myQuestions={questions} answers={answers} onComplete={onComplete} />
                        {/* <Questions answers={answers} onComplete={onComplete} /> */}
                    </Box>
                </Box>}
            {/* {property && <Box row sx={{ background: "white", justifyContent: "left", position: "fixed", right: "8px", bottom: "8px" }}>
                <Button onClick={saveProperty} sx={{ width: "100px" }} variant='contained' color='primary'>
                    Save
                </Button>
            </Box>} */}
        </Box>
    )
}

export default PropertyDetails


// const MyComponent = () => {
//     return (
//         <Box className="MuiBox-root css-r6z5ec">
//             <Box className="MuiBox-root css-9e9q2p" id="form">
//                 <Box className="MuiBox-root css-6w9fxc">
//                     <Box className="MuiBox-root css-1s0nk3x">
//                         <Chip
//                             label="My Properties"
//                             variant="outlined"
//                             size="small"
//                             color="primary"
//                             clickable
//                             icon={<ContactPageIcon />}
//                         />
//                         <Chip
//                             label="Account"
//                             variant="outlined"
//                             size="small"
//                             color="primary"
//                             clickable
//                             icon={<Settings />}
//                         />
//                     </Box>
//                     <Box className="MuiBox-root css-k008qs">
//                         <img
//                             width="60"
//                             height="60"
//                             src="https://media.rightmove.co.uk/1k/679/137023556/679_OXF230213_IMG_00_0000.jpeg"
//                             alt="property"
//                         />
//                         <Box className="MuiBox-root css-ibnnmb">
//                             <Typography variant="h6" className="MuiTypography-root MuiTypography-h6 MuiTypography-noWrap css-3wafqk">
//                                 Woodstock Road, Oxford, OX2
//                             </Typography>
//                             <Typography variant="body1" className="MuiTypography-root MuiTypography-body1 css-9l3uo3">
//                                 £3,500,000
//                             </Typography>
//                             <Button variant="outlined" color="primary" aria-label="Agent's Details">
//                                 <ContactPageIcon />
//                             </Button>
//                         </Box>
//                     </Box>
//                     <Box className="MuiBox-root css-r1j7jb">
//                         <Typography variant="body1" className="MuiTypography-root css-1uk1gs8">
//                             My Score
//                         </Typography>
//                         <Rating
//                             name="highlight-selected-only"
//                             defaultValue={5}
//                             size="medium"
//                             readOnly
//                         />
//                         <TextField
//                             id="description"
//                             name="description"
//                             placeholder="It is worth viewing"
//                             multiline
//                             rows={3}
//                             variant="outlined"
//                             fullWidth
//                         />
//                     </Box>
//                     <Box className="MuiBox-root css-19idom">
//                         <Typography variant="body1" className="MuiTypography-root MuiTypography-h6 css-1anx036">
//                             My Questions
//                         </Typography>
//                         <FormControlLabel
//                             control={<Checkbox />}
//                             label="Can I decorate?"
//                         />
//                     </Box>
//                     <Box className="MuiBox-root css-1i6e9uv">
//                         <Button variant="contained" color="primary" size="small">
//                             Save
//                         </Button>
//                     </Box>
//                 </Box>
//             </Box>
//         </Box>
//     );
// };

// export default MyComponent;
