import React, { useState } from 'react';
import { Container, Typography, Button, Box, List, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer';
import AlarmIcon from '@mui/icons-material/Alarm';
import { Layout } from './Layout';
import SignIn from './SignIn';
import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import { firestore } from '../firebaseConfig';
import { useSelector } from 'react-redux';

const NewInvite = ({ }) => {
    document.title = "New Invite"
    const navigator = useNavigate()
    const authState = useSelector((state) => state);
    const location = useLocation();
    const wildcard = useParams()["*"];
    console.log("wildcard", wildcard, useParams())
    const [open, setOpen] = useState(false)

    const [ownerEmail, setOwnerEmail] = useState(null)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        console.log("wildc"
            , wildcard
        )

        const ref = doc(firestore, "homes", wildcard)


        getDoc(ref).then(res => {

            var home = res.data()
console.log(home)
            if (home) {

                var owner = _.find(home.guests, g => {
                    return g.id == home.owner
                })
                console.log(owner)
                if (owner) {
                    setOwnerEmail(owner.name)
                    setLoading(false)
                }

            }

        })
    }, [])

    if(loading)
    {
        return <Box>
            <Typography>
                Checking the guestlist...
            </Typography>
        </Box>
    }
    return (
        <Layout showMenu={false}>
            <Container maxWidth="sm" sx={{ textAlign: 'center', marginTop: 8, marginBottom: 8 }}>
                <Typography variant="h4" gutterBottom>
                    You're Invited!
                </Typography>
                <Typography variant="h6" gutterBottom>
                    You've been invited to join 
                    <b>
                    &nbsp;{ ownerEmail}'s&nbsp;
                    </b>
                     search
                </Typography>
                <Typography variant="body1" gutterBottom>
                    With homebound, you can do the following:
                </Typography>
                <List sx={{ textAlign: 'left', marginLeft: '25%' }}>
                    <ListItem>
                        <ListItemIcon>
                            <SearchIcon />
                        </ListItemIcon>
                        <ListItemText primary="Search together for your perfect property" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <QuestionAnswerIcon />
                        </ListItemIcon>
                        <ListItemText primary="Get answers for those burning home questions" />
                    </ListItem>
                    <ListItem>
                        <ListItemIcon>
                            <AlarmIcon />
                        </ListItemIcon>
                        <ListItemText primary="Set reminders for viewing and call back dates" />
                    </ListItem>
                </List>
                {authState.user && 
               <Button

               onClick={() => 
                

                {navigator("/properties/", {
                    replace: true,
                
                })
                window.location.reload()}

               }
               sx={{
                   mt: 2,
               }}
               variant="contained" color="primary" size="large">
              Go to Properties
           </Button>
                }
                {!authState.user && <Box mt={4}>
                    <Typography>
                        Sign in to view {ownerEmail}'s properties
                    </Typography>
                    <Button

                        onClick={() => setOpen(true)}
                        sx={{
                            mt: 2,
                        }}
                        variant="contained" color="primary" size="large">
                        Sign In Now
                    </Button>
                </Box>}
                <SignIn open={open}
                //    handleClose={handleClose}
                />
            </Container>
        </Layout>

    );
};

export default NewInvite;
