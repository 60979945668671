import { configureStore } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { mainReducer } from "./slice";
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from "redux-persist";


const persistConfig = {
  key: 'mySuperCoolRootKey',
  storage,
  timeout: null
}

const persistedReducer = persistReducer(persistConfig, mainReducer)
export const store = configureStore({
  reducer: persistedReducer,
  devTools: true,
  // middleware: [thunk],
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});


export const persistor = persistStore(store)
